<template>
    <alliance-profile-component/>
</template>

<script>
    import AllianceProfileComponent from "@/components/alliances/AllianceProfileComponent";

    export default {
        name: "AllianceProfile",
        title: "Perfil de la Alianza | Turismo BC",
        components: { AllianceProfileComponent }
    }
</script>

<style scoped>

</style>